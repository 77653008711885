import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

const AboutPage = () => {
    return (
        <Layout pageTitle="About">
            <p>Working on it</p>
            <StaticImage
                alt="plane"
                src="../images/csm_DA42-VI_DSC1318_5d2fecd8a2.jpg"
            />
        </Layout>
    );
};
export default AboutPage;
