import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
//import insta from "../images/insta5050.svg";
import {
    container,
    heading,
    siteTitle,
    navBar,
    navLinks,
    navLinkItem,
    navLinkText,
} from "/src/components/layout.module.css";

const Layout = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    return (
        <div className={container}>
            <title>
                {pageTitle} | {data.site.siteMetadata.title}
            </title>
            <nav className={navBar}>
                <header className={siteTitle}>
                    <Link to="/" className={navLinkText}>
                        {data.site.siteMetadata.title}
                    </Link>
                </header>
                <ul className={navLinks}>
                    <li className={navLinkItem}>
                        <Link to="/blog" className={navLinkText}>
                            Articles
                        </Link>
                    </li>
                    <li className={navLinkItem}>
                        <Link to="/about" className={navLinkText}>
                            About
                        </Link>
                    </li>
                    {/* <li className={navLinkItem}>
                        <Link to="/contact" className={navLinkText}>
                            Contact
                        </Link>
                    </li>*/}
                </ul>
            </nav>
            <main>
                <h1 className={heading}>{pageTitle}</h1>
                {children}
            </main>
        </div>
    );
};
export default Layout;
